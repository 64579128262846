import { Pipe, PipeTransform } from '@angular/core';

const ENUMS = [
  {displayName: 'APAC - Singapore', value: 'ap'},
  {displayName: 'EU West - Amsterdam', value: 'eu-west-ams'},
  {displayName: 'EU West - Amsterdam', value: 'ams'},
  {displayName: 'US East - Ashburn', value: 'us-east-iad'},
  {displayName: 'US West - Sacramento', value: 'us-west-usw'},

  {displayName: 'All Demand', value: 'OPEN'},
  {displayName: 'Direct Demand Only', value: 'DIRECT'},
  {displayName: 'Nexxen PMPs Only', value: 'PMP'},
  {displayName: 'Direct Demand & Any Deals Only', value: 'DIRECT_PMP'},
  {displayName: 'Publisher Deals Only', value: 'PUBLISHER_PMP'},
  {displayName: 'Open Market Only', value: 'OPEN_MARKET_ONLY' },
];

@Pipe({
  name: 'enums'
})
export class EnumsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const findIndex = ENUMS.findIndex(item => item.value === value);
    return findIndex > -1 ? ENUMS[findIndex].displayName : value;
  }

}
